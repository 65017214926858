﻿/********************************************************************************
	*
	* (c) 2019 - Gehring Technologies GmbH
	*
	* This is the scss file for custom style definitions 
	* needed in the main navigation
	*
	* @author: Woldemar Henning (woldemar.Henning@gehring.de)
	*
*********************************************************************************/

@import "custom_variables";
@import "custom_mixins";
@import "../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";

// MAIN NAVIGATION ON THE LEFT SIDE OF THE SCREEN

.mainnav--fullScreen {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 3009;

	&.togglemode-auto {
		@include media-breakpoint-up(md) {
			display: none;
		}
	}

	&.togglemode-visible {
		display: none;
	}

	&.togglemode-hidden {
	}
}

.mainnav {
	position: relative;
	z-index: 3010;
	// Toggle modes
	&.togglemode-auto {
		.mainnav--content {
			@include media-breakpoint-down(md) {
				position: absolute;
				left: 0;
				top: 0;
				box-shadow: 0 4px 8px 0 $gray-800;

				.multi-collapse-1:not(.toggled) {
					width: 0;
				}

				.multi-collapse-1.toggled {
					width: 4.125rem;
				}
			}

			.mainnav--waves {
				.nav {
					max-width: 15.3125rem;

					.nav-item {
						.nav-link-container {
							&.multi-collapse-2.toggled {
								width: 4.125rem;
							}

							&.multi-collapse-2:not(.toggled) {
								width: 15.3125rem;
							}

							@include media-breakpoint-down(md) {
								&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
									width: 4.125rem;
								}
							}

							.nav-link {
								.mainnav_navtext {
									&.multi-collapse-2.toggled {
										width: 0;
									}

									&.multi-collapse-2:not(.toggled) {
										width: 6.9375rem;

										@include media-breakpoint-down(md) {
											width: 0;
										}
									}
								}
							}

							.nav-link-button {
								&.multi-collapse-2.toggled {
									width: 0;
								}

								&.multi-collapse-2:not(.toggled) {
									width: 3.6875rem;

									@include media-breakpoint-down(md) {
										width: 0;
									}
								}
							}
						}

						.p-subnav-container {
							&:not(.toggled) {
								max-height: 0;

								@include media-breakpoint-down(md) {
									max-height: 2.25rem;
								}
							}

							&.toggled {
								max-height: 2.25rem;
							}

							&.p-subnav-container-visible {
								/* This is for 10 elements + button */
								&:not(.toggled) {
									max-height: 34.75rem;
								}

								&.toggled {
									max-height: 32.5rem;

									@include media-breakpoint-down(md) {
										max-height: 34.75rem;
									}
								}
							}

							&:not(.toggled) {
								.p-subnav-container-subnavtoggler {
									height: 0;

									@include media-breakpoint-down(md) {
										height: 2.25rem;
									}
								}
							}

							&.toggled {
								.p-subnav-container-subnavtoggler {
									height: 2.25rem;
								}
							}

							.p-subnav-container-subnavtoggler {
								@include media-breakpoint-down(md) {
									vertical-align: middle;
									text-align: center;
								}
							}
						}

						.nav-link {
							.mainnav_navtext {
								&.multi-collapse-2.toggled {
									width: 0;
								}

								&.multi-collapse-2:not(.toggled) {
									width: 10.125rem;
								}

								@include media-breakpoint-down(md) {
									&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
										width: 0;
									}
								}
							}
						}

						.p-subnav {
							.p-subnav__link {
								.p-subnav__link--placeholder {
									&.multi-collapse-2.toggled {
										width: 0;
									}

									&.multi-collapse-2:not(.toggled) {
										width: 1.3125rem;
									}

									@include media-breakpoint-down(md) {
										&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
											width: 0;
										}
									}
								}

								.p-subnav__link--text {
									&.multi-collapse-2.toggled {
										width: 0;
									}

									&.multi-collapse-2:not(.toggled) {
										width: 10.25rem;
									}

									@include media-breakpoint-down(md) {
										&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
											width: 0;
										}
									}
								}

								&.multi-collapse-2.toggled {
									width: 0;
								}

								&.multi-collapse-2:not(.toggled) {
									width: 10.25rem;
								}
							}

							@include media-breakpoint-down(md) {
								position: fixed;
								left: 4.375rem;
								background-image: linear-gradient(to top, $mainnav-bg-dark -50%, $mainnav-bg-light 150%);
								box-shadow: 0 4px 8px 0 $gray-800;
							}

							@include media-breakpoint-up(md) {
								display: unset;
								height: unset;
								width: unset;
								overflow: unset;
							}
						}

						.p-subnav {
							@include media-breakpoint-up(md) {
								height: 0;
								width: 0;
								overflow: hidden;
							}
						}
					}
				}
			}
		}

		.p-search-form {
			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		&.toggled {
			.mainnav--content {
				@include media-breakpoint-down(md) {
					width: 4.125rem;
				}

				@include media-breakpoint-up(md) {
					width: 4.125rem;
				}
			}
		}

		&:not(.toggled) {
			.mainnav--content {
				@include media-breakpoint-down(md) {
					width: 0;

					.mainnav--waves .nav .nav-item .p-subnav {
						width: 0;
					}
				}

				@include media-breakpoint-up(md) {
					width: 15.3125rem;
				}
			}
		}
	}

	&.togglemode-visible {
		.mainnav--content {
			.mainnav--waves {
				.nav {
					max-width: 15.3125rem;

					.nav-item {
						.nav-link-container {
							&.multi-collapse-2.toggled {
								width: 4.125rem;
							}

							&.multi-collapse-2:not(.toggled) {
								width: 15.3125rem;
							}

							.nav-link {
								.mainnav_navtext {
									&.multi-collapse-2.toggled {
										width: 0;
									}

									&.multi-collapse-2:not(.toggled) {
										width: 6.9375rem;
									}
								}
							}

							.nav-link-button {
								&.multi-collapse-2.toggled {
									width: 0;
								}

								&.multi-collapse-2:not(.toggled) {
									width: 3.6875rem;
								}
							}
						}

						.p-subnav-container {
							&:not(.toggled) {
								max-height: 0;
							}

							&.toggled {
								max-height: 2.25rem;
							}

							&.p-subnav-container-visible {
								/* This is for 10 elements + button */
								&:not(.toggled) {
									max-height: 34.75rem;
								}

								&.toggled {
									max-height: 32.5rem;
								}
							}

							&:not(.toggled) {
								.p-subnav-container-subnavtoggler {
									height: 0;
								}
							}

							&.toggled {
								.p-subnav-container-subnavtoggler {
									height: 2.25rem;
								}
							}

							.p-subnav-container-subnavtoggler {
								vertical-align: middle;
								text-align: center;
							}
						}

						.nav-link {
							.mainnav_navtext {
								&.multi-collapse-2.toggled {
									width: 0;
								}

								&.multi-collapse-2:not(.toggled) {
									width: 10.125rem;
								}
							}
						}

						.p-subnav {
							.p-subnav__link {
								.p-subnav__link--placeholder {
									&.multi-collapse-2.toggled {
										width: 0;
									}

									&.multi-collapse-2:not(.toggled) {
										width: 1.3125rem;
									}
								}

								.p-subnav__link--text {
									&.multi-collapse-2.toggled {
										width: 0;
									}

									&.multi-collapse-2:not(.toggled) {
										width: 10.25rem;
									}
								}

								&.multi-collapse-2.toggled {
									width: 0;
								}

								&.multi-collapse-2:not(.toggled) {
									width: 10.25rem;
								}
							}

							display: unset;
							height: unset;
							width: unset;
							overflow: unset;
						}

						.p-subnav {
							height: 0;
							width: 0;
							overflow: hidden;
						}
					}
				}
			}
		}

		&.toggled {
			.mainnav--content {
				width: 4.125rem;
			}
		}

		&:not(.toggled) {
			.mainnav--content {
				width: 15.3125rem;
			}
		}
	}

	&.togglemode-hidden {
		.mainnav--content {
			position: absolute;
			left: 0;
			top: 0;
			box-shadow: 0 4px 8px 0 $gray-800;

			.multi-collapse-1:not(.toggled) {
				width: 0;
			}

			.multi-collapse-1.toggled {
				width: 4.125rem;
			}

			.mainnav--waves {
				.nav {
					max-width: 15.3125rem;

					.nav-item {
						.nav-link-container {
							&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
								width: 4.125rem;
							}

							.nav-link {
								.mainnav_navtext {
									&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
										width: 0;
									}
								}
							}

							.nav-link-button {
								&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
									width: 0;
								}
							}
						}

						.p-subnav-container {
							&:not(.toggled), &.toggled {
								max-height: 2.25rem;
							}

							&.p-subnav-container-visible {
								/* This is for 10 elements + button */
								&:not(.toggled), &.toggled {
									max-height: 34.75rem;
								}
							}

							&:not(.toggled), &.toggled {
								.p-subnav-container-subnavtoggler {
									height: 2.25rem;
								}
							}

							.p-subnav-container-subnavtoggler {
								vertical-align: middle;
								text-align: center;
							}
						}

						.nav-link {
							.mainnav_navtext {
								&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
									width: 0;
								}
							}
						}

						.p-subnav {
							.p-subnav__link {
								.p-subnav__link--placeholder {
									&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
										width: 0;
									}
								}

								.p-subnav__link--text {
									&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
										width: 0;
									}
								}

								&.multi-collapse-2.toggled {
									width: 0;
								}

								&.multi-collapse-2:not(.toggled) {
									width: 10.25rem;
								}
							}

							position: fixed;
							left: 4.375rem;
							background-image: linear-gradient(to top, $mainnav-bg-dark -50%, $mainnav-bg-light 150%);
							box-shadow: 0 4px 8px 0 $gray-800;
						}
					}
				}
			}
		}

		.p-search-form {
			display: none;
		}

		&.toggled {
			.mainnav--content {
				width: 4.125rem;
			}
		}

		&:not(.toggled) {
			.mainnav--content {
				width: 0;

				.mainnav--waves .nav .nav-item .p-subnav {
					width: 0;
				}
			}
		}
	}
	// toggler for the main navigation
	.mainnav__nav-toggler {
		@include nav-appearance;
		padding-top: 0.625rem;

		.navbar-toggler-icon {
			@include navbar-toggler-icons;
			background-position-x: 0;
		}
	}
	// Main navigation content
	.mainnav--content {
		-webkit-transition: width 0.35s ease;
		-moz-transition: width 0.35s ease;
		-o-transition: width 0.35s ease;
		transition: width 0.35s ease;
		overflow: hidden;
		z-index: 3020;
	}

	.p-search-form {
		width: 0;
		-webkit-transition: width 0.35s ease;
		-moz-transition: width 0.35s ease;
		-o-transition: width 0.35s ease;
		transition: width 0.35s ease;
		overflow: hidden;
	}
}

.multi-collapse-1, .multi-collapse-2 {
	width: 0;
	-webkit-transition: width 0.35s ease;
	-moz-transition: width 0.35s ease;
	-o-transition: width 0.35s ease;
	transition: width 0.35s ease;
	overflow: hidden;
}

.p-search-form {
	top: 1rem;
	left: 1rem;
	z-index: 3400;

	&:not(.toggled) {
		width: 12.875rem;
	}

	div {
		button {
			width: 2.125rem;
			height: 2.125rem;
			border: 1px solid $gray-500;
			border-right: none;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			background: $white;
			padding-top: 0.125rem;
			padding-bottom: 0.125rem;

			.icon--search {
				background-position: -100px -47px;
				width: 1.6875rem;
				height: 1.75rem;
				display: inline-block;
				border-right: 1px solid $gray-500;
			}
		}

		input {
			width: 10.9375rem;
			border-left: none;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-color: $gray-500;
		}
	}
}

/* Content of the MainNav */
/*.mainnav {
	.mainnav--content {
		background-image: linear-gradient(to top, $mainnav-bg-dark 0%, $mainnav-bg-light 100%);*/
		/* the full scrollable navigation block with the waves background image */
		/*.mainnav--waves {
			background: url(../images/waves.png) no-repeat -40px bottom;
			height: calc(100vh - 70px);

			.mainnav--waves {
				background: transparent;
			}

			.nav {
				max-width: 15.3125rem;

				.nav-item {

					.nav-link-container {
						@include nav-appearance;

						&.multi-collapse-2.toggled {
							width: 4.125rem;
						}

						&.multi-collapse-2:not(.toggled) {
							width: 15.3125rem;
						}

						@include media-breakpoint-down(md) {
							&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
								width: 4.125rem;
							}
						}

						.nav-link {
							.mainnav_navtext {
								display: flex;
								justify-content: space-between;
								align-items: center;
								white-space: nowrap;

								&.multi-collapse-2.toggled {
									width: 0;
								}

								&.multi-collapse-2:not(.toggled) {
									width: 6.9375rem;

									@include media-breakpoint-down(md) {
										width: 0;
									}
								}
							}
						}

						.nav-link-button {
							background: rgba(61, 61, 61, 0.4);
							cursor: pointer;
							margin-left: 0.5rem;

							button {
								@include nav-icon-size;
								background-position: -250px -1000px;
								width: 0.75rem;
								height: 0.875rem;
								margin-right: 0.375rem;
								margin: 1.1875rem 0.5rem 1.1875rem 1.4375rem;
								border: none;

								&:focus {
									outline: none;
								}
							}

							&:hover {
								color: $red;
								background: rgba(61, 61, 61, 0.8);

								button {
									background-position: -100px -200px;
								}
							}

							&.p-subnav-container-visible {
								button {
									background-position: -300px -1000px;
									width: 0.75rem;
									height: 0.5rem;
									margin-right: 0.375rem;
									margin: 1.375rem 0.5rem 1.375rem 1.4375rem;
								}

								&:hover {
									button {
										background-position: -350px -1000px;
									}
								}
							}

							&.multi-collapse-2.toggled {
								width: 0;
							}

							&.multi-collapse-2:not(.toggled) {
								width: 3.6875rem;

								@include media-breakpoint-down(md) {
									width: 0;
								}
							}
						}
					}

					.p-subnav-container {
						-webkit-transition: max-height 0.35s ease;
						-moz-transition: max-height 0.35s ease;
						-o-transition: max-height 0.35s ease;
						transition: max-height 0.35s ease;
						overflow: hidden;

						&:not(.toggled) {
							max-height: 0;

							@include media-breakpoint-down(md) {
								max-height: 2.25rem;
							}
						}

						&.toggled {
							max-height: 2.25rem;
						}

						&.p-subnav-container-visible {*/
							/* This is for 10 elements + button */
							/*&:not(.toggled) {
								max-height: 34.75rem;
							}

							&.toggled {
								max-height: 32.5rem;

								@include media-breakpoint-down(md) {
									max-height: 34.75rem;
								}
							}
						}

						.p-subnav-container-subnavtoggler {
							display: flex;
							align-items: center;
							width: unset;
							overflow: hidden;
							cursor: pointer;
							-webkit-transition: height 0.35s ease;
							-moz-transition: height 0.35s ease;
							-o-transition: height 0.35s ease;
							transition: height 0.35s ease;

							.p-subnav-container-subnavtoggler-styling {
								background: rgba(61, 61, 61, 0.4);
								height: 2rem;
								display: block;
								margin-bottom: 0.25rem;
								padding-top: 0.25rem;
							}

							button {
								@include arrow-icon;
								margin: 0.5rem 1.6875rem 0.5rem 1.6875rem;
								border: none;

								&:focus {
									outline: none;
								}
							}

							&:hover {
								color: $red;

								.p-subnav-container-subnavtoggler-styling {
									background: rgba(61, 61, 61, 0.8);
								}

								button {
									background-position: -100px -200px;
								}
							}
						}

						&:not(.toggled) {
							.p-subnav-container-subnavtoggler {
								height: 0;

								@include media-breakpoint-down(md) {
									height: 2.25rem;
								}
							}
						}

						&.toggled {
							.p-subnav-container-subnavtoggler {
								height: 2.25rem;
							}
						}

						&.p-subnav-container-visible {
							& .p-subnav-container-subnavtoggler {
								button {
									background-position: -300px -1000px;
								}

								&:hover {
									button {
										background-position: -350px -1000px;
									}
								}
							}
						}
					}

					.nav-link {
						@include nav-appearance;
						margin-top: 0.1875rem;
						margin-bottom: 0.1875rem;

						.mainnav__navicon {
							@include nav-icon-size;
							background-position-x: 0px;
							margin: 0.9375rem 0.5rem 0.9375rem 1.4375rem;
						}

						.mainnav_navtext {
							display: flex;
							justify-content: space-between;
							align-items: center;
							white-space: nowrap;

							&.multi-collapse-2.toggled {
								width: 0;
							}

							&.multi-collapse-2:not(.toggled) {
								width: 10.125rem;
							}

							@include media-breakpoint-down(md) {
								&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
									width: 0;
								}
							}
						}

						&:hover {
							color: $red;
							background: rgba(61, 61, 61, 0.4);

							.mainnav__navicon {
								background-position-x: -50px;
							}
						}
					}

					&.active, &.child-active {

						.nav-link-container {
							background: $gray-800;
							border-left: 5px solid $red;
						}

						.nav-link {
							.mainnav__navicon {
								background-position-x: -50px;
								margin-left: 1.125rem;
							}

							.icon--arrow {
								background-position: -300px -1000px;
								width: 0.75rem;
								height: 0.5rem;
								margin-right: 0.375rem;
							}

							&:hover {
								background: rgb(0,0,0);

								.icon--arrow {
									background-position: -350px -1000px;
								}
							}
						}
					}

					&.child-active {
						.nav-link {
							border-left: none;

							.mainnav__navicon {
								background-position-x: 0;
								margin-left: 1.125rem;
							}

							&:hover {
								.mainnav__navicon {
									background-position-x: -50px;
								}
							}

							.icon--arrow {
								background-position: -300px -1000px;
								width: 0.75rem;
								height: 0.5rem;
							}
						}
					}

					.p-subnav {
						z-index: 4000;

						.p-subnav__link {
							@include nav-appearance;
							margin: 0;
							font-weight: normal;
							background: rgba(123, 123, 123, 0.4);
							max-height: 3.25rem;

							.p-subnav__link--placeholder {
								margin: 0;

								&.multi-collapse-2.toggled {
									width: 0;
								}

								&.multi-collapse-2:not(.toggled) {
									width: 1.3125rem;
								}

								@include media-breakpoint-down(md) {
									&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
										width: 0;
									}
								}
							}

							.p-subnav__link--text {
								&.multi-collapse-2.toggled {
									width: 0;
								}

								&.multi-collapse-2:not(.toggled) {
									width: 10.25rem;
								}

								@include media-breakpoint-down(md) {
									&.multi-collapse-2.toggled, &.multi-collapse-2:not(.toggled) {
										width: 0;
									}
								}
							}

							span.bg-sprite {
								margin: 0.9375rem 0.625rem 0.9375rem 1.4375rem;
								width: 1.6875rem;
								height: 1.375rem;
								display: inline-block;
							}

							&:hover {
								color: $red;
								text-decoration: none;
								background: rgba(123, 123, 123, 0.8);

								span.bg-sprite {
									background-position-x: -50px;
								}
							}

							&.multi-collapse-2.toggled {
								width: 0;
							}

							&.multi-collapse-2:not(.toggled) {
								width: 10.25rem;
							}
						}

						.active {
							.p-subnav__link {
								background: rgba(61, 61, 61, 0.4);
								border-left: 5px solid $red;

								&:hover {
									background: rgba(61, 61, 61, 0.8);
								}

								span.bg-sprite {
									margin-left: 1.125rem;
									background-position-x: -50px;
								}
							}
						}

						@include media-breakpoint-down(md) {
							position: fixed;
							left: 4.375rem;
							margin-top: 3.875rem;
							background-image: linear-gradient(to top, $mainnav-bg-dark -50%, $mainnav-bg-light 150%);
							box-shadow: 0 4px 8px 0 $gray-800;
						}

						@include media-breakpoint-up(md) {
							display: unset;
							height: unset;
							width: unset;
							overflow: unset;
						}
					}

					.p-subnav {
						@include media-breakpoint-up(md) {
							height: 0;
							width: 0;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
}*/



.mainnav {
	.mainnav--content {
		background-image: linear-gradient(to top, $mainnav-bg-dark 0%, $mainnav-bg-light 100%);
		/* the full scrollable navigation block with the waves background image */
		.mainnav--waves {
			background: url(../images/waves.png) no-repeat -40px bottom;
			height: calc(100vh - 70px);

			.mainnav--waves {
				background: transparent;
			}

			.nav {
				.nav-item {

					.nav-link-container {
						@include nav-appearance;

						.nav-link {
							.mainnav_navtext {
								display: flex;
								justify-content: space-between;
								align-items: center;
								white-space: nowrap;
							}
						}

						.nav-link-button {
							background: rgba(61, 61, 61, 0.4);
							cursor: pointer;
							margin-left: 0.5rem;

							button {
								@include nav-icon-size;
								background-position: -250px -1000px;
								width: 0.75rem;
								height: 0.875rem;
								margin-right: 0.375rem;
								margin: 1.1875rem 0.5rem 1.1875rem 1.4375rem;
								border: none;

								&:focus {
									outline: none;
								}
							}

							&:hover {
								color: $red;
								background: rgba(61, 61, 61, 0.8);

								button {
									background-position: -100px -200px;
								}
							}

							&.p-subnav-container-visible {
								button {
									background-position: -300px -1000px;
									width: 0.75rem;
									height: 0.5rem;
									margin-right: 0.375rem;
									margin: 1.375rem 0.5rem 1.375rem 1.4375rem;
								}

								&:hover {
									button {
										background-position: -350px -1000px;
									}
								}
							}
						}
					}

					.p-subnav-container {
						-webkit-transition: max-height 0.35s ease;
						-moz-transition: max-height 0.35s ease;
						-o-transition: max-height 0.35s ease;
						transition: max-height 0.35s ease;
						overflow: hidden;

						.p-subnav-container-subnavtoggler {
							display: flex;
							align-items: center;
							width: unset;
							overflow: hidden;
							cursor: pointer;
							-webkit-transition: height 0.35s ease;
							-moz-transition: height 0.35s ease;
							-o-transition: height 0.35s ease;
							transition: height 0.35s ease;

							.p-subnav-container-subnavtoggler-styling {
								background: rgba(61, 61, 61, 0.4);
								height: 2rem;
								display: block;
								margin-bottom: 0.25rem;
								padding-top: 0.25rem;
							}

							button {
								@include arrow-icon;
								margin: 0.5rem 1.6875rem 0.5rem 1.6875rem;
								border: none;

								&:focus {
									outline: none;
								}
							}

							&:hover {
								color: $red;

								.p-subnav-container-subnavtoggler-styling {
									background: rgba(61, 61, 61, 0.8);
								}

								button {
									background-position: -100px -200px;
								}
							}
						}

						&.p-subnav-container-visible {
							& .p-subnav-container-subnavtoggler {
								button {
									background-position: -300px -1000px;
								}

								&:hover {
									button {
										background-position: -350px -1000px;
									}
								}
							}
						}
					}

					.nav-link {
						@include nav-appearance;
						margin-top: 0.1875rem;
						margin-bottom: 0.1875rem;

						.mainnav__navicon {
							@include nav-icon-size;
							background-position-x: 0px;
							margin: 0.9375rem 0.5rem 0.9375rem 1.4375rem;
						}

						.mainnav_navtext {
							display: flex;
							justify-content: space-between;
							align-items: center;
							white-space: nowrap;
						}

						&:hover {
							color: $red;
							background: rgba(61, 61, 61, 0.4);

							.mainnav__navicon {
								background-position-x: -50px;
							}
						}
					}

					&.active, &.child-active {

						.nav-link-container {
							background: $gray-800;
							border-left: 5px solid $red;
						}

						.nav-link {
							.mainnav__navicon {
								background-position-x: -50px;
								margin-left: 1.125rem;
							}

							.icon--arrow {
								background-position: -300px -1000px;
								width: 0.75rem;
								height: 0.5rem;
								margin-right: 0.375rem;
							}

							&:hover {
								background: rgb(0,0,0);

								.icon--arrow {
									background-position: -350px -1000px;
								}
							}
						}
					}

					&.child-active {
						.nav-link {
							border-left: none;

							.mainnav__navicon {
								background-position-x: 0;
								margin-left: 1.125rem;
							}

							&:hover {
								.mainnav__navicon {
									background-position-x: -50px;
								}
							}

							.icon--arrow {
								background-position: -300px -1000px;
								width: 0.75rem;
								height: 0.5rem;
							}
						}
					}

					.p-subnav {
						z-index: 4000;

						.p-subnav__link {
							@include nav-appearance;
							margin: 0;
							font-weight: normal;
							background: rgba(123, 123, 123, 0.4);
							max-height: 3.25rem;

							.p-subnav__link--placeholder {
								margin: 0;
							}

							span.bg-sprite {
								margin: 0.9375rem 0.625rem 0.9375rem 1.4375rem;
								width: 1.6875rem;
								height: 1.375rem;
								display: inline-block;
							}

							&:hover {
								color: $red;
								text-decoration: none;
								background: rgba(123, 123, 123, 0.8);

								span.bg-sprite {
									background-position-x: -50px;
								}
							}
						}

						.active {
							.p-subnav__link {
								background: rgba(61, 61, 61, 0.4);
								border-left: 5px solid $red;

								&:hover {
									background: rgba(61, 61, 61, 0.8);
								}

								span.bg-sprite {
									margin-left: 1.125rem;
									background-position-x: -50px;
								}
							}
						}
					}
				}
			}
		}
	}
}
