﻿/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is the scss file for icons
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

@import "custom_variables";
@import "../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.btn {
	.text {
		width: 100%;
	}

	.icon {
		position: relative;
		top: -0.0625rem;

		&:not(:last-child) {
			margin-right: 0.25rem;
			align-items: center;
			display: flex;
		}

		.svgIcon {
			height: 1.125rem;
			fill: $white;
			stroke: $white;
		}
	}

	&.btn-lg {
		.icon {
			top: -0.125rem;

			&:not(:last-child) {
				margin-right: 0.3125rem;
			}

			.svgIcon {
				height: 1.375rem;
			}
		}
	}

	&.btn-sm {
		.icon {
			&:not(:last-child) {
				margin-right: 0.1875rem;
			}

			.svgIcon {
				height: 0.9375rem;
			}
		}
	}

	&.btn-light {
		.svgIcon {
			fill: #000;
			stroke: #000;
		}
	}

	&.btn-success {
		.svgIcon {
			fill: #FFF;
			stroke: #FFF;
		}
	}

	&.btn-outline-secondary {
		.svgIcon {
			transition: fill 0.15s ease-in-out, stroke 0.15s ease-in-out;
			fill: $gray-700;
			stroke: $gray-700;
		}
	}

	&.btn-link {
		.svgIcon {
			transition: fill 0.15s ease-in-out, stroke 0.15s ease-in-out;
			fill: $gray-600;
			stroke: $gray-600;
		}
	}

	&:hover {
		&.btn-link {
			.svgIcon {
				fill: $red;
				stroke: $red;
			}
		}

		&.btn-outline-secondary {
			.svgIcon {
				fill: $white;
				stroke: $white;
			}
		}
	}
	// Hide below size
	&.autohide-sm {
		@include media-breakpoint-down(sm) {
			.icon:not(:last-child) {
				margin-right: 0;
			}

			.text {
				display: none;
			}
		}
	}

	&.autohide-md {
		@include media-breakpoint-down(md) {
			.icon:not(:last-child) {
				margin-right: 0;
			}

			.text {
				display: none;
			}
		}
	}

	&.autohide-lg {
		@include media-breakpoint-down(lg) {
			.icon:not(:last-child) {
				margin-right: 0;
			}

			.text {
				display: none;
			}
		}
	}

	&.autohide-xl {
		@include media-breakpoint-down(xl) {
			.icon:not(:last-child) {
				margin-right: 0;
			}

			.text {
				display: none;
			}
		}
	}

	&.autohide-xxl {
		@include media-breakpoint-down(xxl) {
			.icon:not(:last-child) {
				margin-right: 0;
			}

			.text {
				display: none;
			}
		}
	}
}