﻿/********************************************************************************
	*
	* (c) 2019 - Gehring Technologies GmbH
	*
	* This is the scss file for custom style definitions and overrides for bootstrap 4.3.
	*
	* @author: Woldemar Henning (woldemar.Henning@gehring.de)
	*
*********************************************************************************/

// import custom variable override
@import "custom_variables";

.chart_tooltip {
	font-family: $font-family-sans-serif !important;

	.title {
		font-size: $font-size-base;
		color: $gray-600;
		font-weight: $font-weight-bold;
		line-height: 1;
	}

	.items {
		.item {
			clear: both;
			margin: 10px 0 0;
			line-height: 1;

			.circle {
				display: inline-block;
				margin-right: 4px;
				border-radius: 10px;
				width: 10px;
				height: 10px;
			}

			.label {
				font-size: $font-size-base;
				color: $gray-600;
				font-weight: $font-weight-normal;
				margin-left: 2px
			}

			.value {
				float: right;
				margin-left: 20px;
				font-size: $font-size-base;
				color: $gray-600;
				font-weight: $font-weight-bold
			}
		}
	}
}