﻿/********************************************************************************
	*
	* (c) 2019 - Gehring Technologies GmbH
	*
	* This is the bootstrat override scss file.
	* Bootstrap classes will be overridden by custom definitions. 
	*
	* @author: Woldemar Henning (woldemar.Henning@gehring.de)
	*
*********************************************************************************/

@import "custom_variables";
@import "../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";
@import "../../node_modules/bootstrap/scss/mixins/_buttons.scss";

// Button color
.btn-success {
	@include button-variant($success, $success, $color: $white, $hover-color: $white, $active-color: $white, $disabled-color: $white);
}

// BREADCRUMB IN HEADER
.breadcrumb {
	margin: 0;
	padding: 0;
	background: none;
	border-radius: 0;
	font-size: $font-size-sm;

	.breadcrumb-item {
		a {
			text-decoration: underline;
			color: $gray-800;

			&:hover {
				color: $red;
			}
		}

		& + .breadcrumb-item::before {
			color: $gray-700;
			font-weight: normal;
			content: "//";
			padding: 0 0.375rem 0 0.125rem;
		}

		&.active {
			font-weight: bold;
			color: $gray-700;
		}
	}
}

// TAB NAVIGATION

.nav-tabs {
	border-bottom-color: transparent;
	padding-left: 1.25rem;

	.nav-link,
	.dropdown-item {
		padding: 0.25rem 1.125rem 0.4375rem 1.125rem;
		color: $gray-800;
		background-color: $white;
		border-color: $gray-300 $gray-300 $white $gray-300;
		@include border-top-radius(0);
		transform: translate(0, 0.25rem);
		margin-right: -0.0625rem;
		position: relative;
		z-index: 1;
		cursor: pointer;

		&:hover {
			color: $red;
			border-color: $gray-300 $gray-300 $white $gray-300;
		}
	}

	.nav-link.active,
	.nav-item.show .nav-link,
	.dropdown-item.active {
		font-weight: bold;
		background-color: $white;
		border-color: $gray-500 $gray-500 $white $gray-500;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		@include border-top-radius($nav-tabs-border-radius);
		transform: translate(0);
		z-index: 10;
		margin-bottom: -0.0625rem;
	}
}

.tab-nav {
	padding-bottom: 0;
	margin: 0;
	border: none;

	&:not(.tabs-to-dropdown) {
		.tab-nav__nav-block {
			display: block;
			flex-grow: 1;
			border-bottom: 1px solid $gray-500;
			margin-bottom: 0.25rem;

			&.collapsing {
				transition: none;
				height: unset;
			}
		}
	}

	// TRANSFORM TAB NAVIGATION INTO DROPDOWN NAVIGATION (by adding this class via JS to the .tab-nav class)
	&.tabs-to-dropdown {
		@include tabs-go-vertical;

		.tab-nav__btn--toggl {
			display: inline-block !important;
		}
	}
}

// FORM INPUT
label {
	margin-bottom: 0.375rem;
}

fieldset[disabled] {
	color: $gray-400 !important;

	label {
		color: $gray-400;
	}
}

// LEAD
.lead {
	font-weight: normal;
}

// LIST GROUP
.list-group-item.active {
	color: $gray-800;
	background-color: $white;
	border-color: $purple;
}

// TABLES

.table {
	th, td {
		padding-left: 0.9375rem;
		vertical-align: middle;

		@include media-breakpoint-down(md) {
			padding-left: 0.625rem;
		}

		&:last-child {

			.td--border-end {
				border-right: none;
			}
		}

		&:first-child {

			.td--border-start {
				border-left: none;
			}
		}
	}

	td {
		padding-top: 0.1875rem;
		padding-bottom: 0.1875rem;
		padding-right: 0;

		span {
			padding-right: 0.9375rem;
			display: inline-block;

			@include media-breakpoint-down(md) {
				padding-right: 0.625rem;
			}
		}
	}

	th {
		border-top: none;
		white-space: nowrap;
		padding-right: 0.9375rem;

		@include media-breakpoint-down(md) {
			padding-right: 0.625rem;
		}
	}

	tbody {
		border-bottom: 1px solid $gray-300;
	}

	a {
		text-decoration: underline;

		&:hover {
			color: $red;
		}
	}
}

.table-striped {
	tbody tr:nth-of-type(odd) {
		background-color: $gray-100;
	}
}


// CARDS

.card {
	border-color: $gray-300;
	background-color: $white;
	padding: 0.1875rem;
	height: 100%;

	.card-header {
		background-color: $white;
		padding: 0.75rem;

		h5, .h5, h6, .h6 {
			margin-bottom: 0;
		}

		.unwrap {
			margin-top: 1.2rem;
		}

		.last {
			margin-top: -1.2rem;
			height: 20px;
		}
	}

	.card-body {
		height: 100%;
	}

	.card__text-lg {
		padding: 0 1rem;
		font-size: $font-size-lg;
		line-height: $line-height-lg;
	}

	.card__text-14 {
		font-size: 0.875rem;
		line-height: $line-height-lg;
	}

	.card-text-600 {
		font-weight: $headings-font-weight;
	}

	.card__chart {
		height: 100%;
		width: 100%;
		min-height: 20rem;
	}

	.card__chart-large {
		height: 30rem;
		width: 100%;
	}

	.card__chart-small {
		height: 10rem;
		width: 100%;
	}

	.card__chart-26 {
		height: 25.5625rem;
		width: 100%;
	}

	.card__table {
		height: 20rem;
		width: 100%;
	}
}


// MODAL POPUP

.modal {
	.modal-dialog {

		.modal-content {
			border: none;

			.modal-header {
				border: 1px solid $gray-400;
				border-bottom: transparent;
			}

			hr.body-separation {
				margin: -0.0625rem 0.25rem 0 0.25rem;
			}

			.modal-body {
				border-left: 1px solid $gray-400;
				border-right: 1px solid $gray-400;

				.card__chart {
					height: 25.3125rem;

					@media (max-width: 1199.98px) {
						height: 21rem;
					}

					@media (max-width: 991.98px) {
						height: 19rem;
					}

					@media (max-width: 767.98px) {
						height: 17rem;
					}
				}
			}

			.modal-footer {
				padding: 0;
				border-top: none;
				justify-content: flex-start;
				align-items: normal;

				.btn {
					margin: 0;
					border-radius: 0;
					width: 50%;
					color: white;
					font-weight: bold;
					text-transform: uppercase;

					&.btn-modal--start {
						@include no-focus;
						border-bottom-left-radius: 0.25rem;
						background-color: $gray-400;

						&:hover {
							background-color: $gray-600;
						}

						&[disabled]:hover {
							background-color: $gray-400;
						}
					}

					&.btn-modal--end {
						@include no-focus;
						border-bottom-right-radius: 0.25rem;
						background-color: $orange;

						&:hover {
							background-color: $orange-dark;
						}

						&[disabled]:hover {
							background-color: $orange;
						}
					}

					&.btn-modal--single {
						@include no-focus;
						width: 100%;
						border-bottom-right-radius: 0.25rem;
						border-bottom-left-radius: 0.25rem;
						background-color: $orange;

						&:hover {
							background-color: $orange-dark;
						}

						&[disabled]:hover {
							background-color: $orange;
						}
					}
				}
			}

			.modal-checkbox {
				width: 1.25rem;
				height: 1.25rem;
			}
		}

		&.machine__modal {
			@media (max-width: 1199.98px) {
				max-width: 50rem;
			}

			@media (max-width: 991.98px) {
				max-width: 45rem;
			}

			@media (min-width: 576px) {
				max-width: 37.5rem;
			}

			@media (min-width: 1200px) {
				max-width: 100%;
			}
		}
	}
}


// BUTTON
.btn-primary {
	border-color: $purple;
	background-color: $purple-lighter;
}

.btn-info {
	border-color: $cyan-dark !important;

	&:hover {
		border-color: $cyan-darker !important;
	}

	&:disabled {
		pointer-events: none;
	}
}

.btn-success {
	border-color: $cyan-dark !important;
	background-color: $cyan-dark !important;

	&:hover {
		border-color: $cyan-darker !important;
		background-color: $cyan-darker !important;
	}

	&:disabled {
		pointer-events: none;
	}
}

// SIZE
$sizes: ( 
	25: 25%,
	33: 33.3333%,
	50: 50%, 
	67: 66.6666%, 
	75: 75%, 
	100: 100%,
	auto: auto,
);

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		@each $prop, $abbrev in (width: w, height: h) {
			@each $size, $length in $sizes {
				.#{$abbrev}#{$infix}-#{$size} {
					#{$prop}: $length !important;
				}
			}
		}
	}
}

// Remove the dropdown arrow
.btn-icon {
	&.btn-icon--set-timerange {
		&.dropdown-toggle {
			&::after, &[disabled]:hover::after {
				display: initial;
				margin-left: initial;
				vertical-align: initial;
			//	content: initial;
				border-top: initial;
				border-right: initial;
				border-bottom: initial;
				border-left: initial;
			}
		}
	}
}

// Tooltip should not display the scrollbars temporary
.tooltip {
	position: absolute;
	top: 0;
	left: 0;
}