﻿/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the bootstrat override scss file.
* Bootstrap classes will be overridden by custom definitions. 
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

.react-datepicker__input-container {
    width: inherit;
}

.react-datepicker-wrapper {
    width: 100%;
}