﻿/********************************************************************************
*
* (c) 2021 - Gehring Technologies GmbH
*
* This is the definition for the rc-input-element
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

// import custom variable override
@import "custom_variables";


$inputNumberPrefixCls: rc-input-number;

.#{$inputNumberPrefixCls} {
    display: inline-block;
    height: 26px;
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 26px;
    vertical-align: middle;
    border: 1px solid $gray-500;
    border-radius: 4px;
    transition: all 0.3s;

    &-focused {
        color: $gray-700;
        border-color: #fb6d76;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(227, 6, 19, 0.25);
    }

    &-out-of-range {
        input {
            color: red;
        }
    }

    &-handler {
        display: block;
        height: 12px;
        overflow: hidden;
        line-height: 12px;
        text-align: center;
        touch-action: none;

        &-active {
            background: #ddd;
        }
    }

    &-handler-up-inner,
    &-handler-down-inner {
        color: $gray-700;
        -webkit-user-select: none;
        user-select: none;
    }

    &:hover {
        border-color: $gray-500;

        .#{$inputNumberPrefixCls}-handler-up,
        .#{$inputNumberPrefixCls}-handler-wrap {
            border-color: $gray-500;
        }
    }

    &-focused:hover {
        border-color: #fb6d76;
    }

    &-disabled:hover {
        border-color: $gray-300;

        .#{$inputNumberPrefixCls}-handler-up,
        .#{$inputNumberPrefixCls}-handler-wrap {
            border-color: $gray-300;
        }
    }

    &-input-wrap {
        height: 100%;
        overflow: hidden;
    }

    &-input {
        width: 100%;
        height: 100%;
        padding: 0;
        color: $gray-700;
        line-height: 26px;
        text-align: center;
        border: 0;
        border-radius: 4px;
        outline: 0;
        transition: all 0.3s ease;
        transition: all 0.3s;
        -moz-appearance: textfield;
    }

    &-handler-wrap {
        float: right;
        width: 20px;
        height: 100%;
        border-left: 1px solid $gray-500;
        transition: all 0.3s;
    }

    &-handler-up {
        padding-top: 1px;
        border-bottom: 1px solid $gray-500;
        transition: all 0.3s;

        &-inner {
            &:after {
                content: '+';
            }
        }
    }

    &-handler-down {
        transition: all 0.3s;

        &-inner {
            &:after {
                content: '-';
            }
        }
    }

    &-handler-down-disabled,
    &-handler-up-disabled {
        opacity: 0.3;

        &:hover {
            color: $gray-700;
            border-color: $gray-500;
        }
    }

    &-disabled {
        border-color: $gray-300;

        .#{$inputNumberPrefixCls}-handler-up,
        .#{$inputNumberPrefixCls}-handler-wrap {
            border-color: $gray-300;
        }

        .#{$inputNumberPrefixCls}-input {
            background-color: $gray-100;
            border-color: $gray-300;
            cursor: not-allowed;
            opacity: 0.72;
        }

        .#{$inputNumberPrefixCls}-handler {
            opacity: 0.3;

            &:hover {
                color: $gray-100;
                border-color: $gray-300;
            }
        }
    }
}
