﻿/********************************************************************************
	*
	* (c) 2019 - Gehring Technologies GmbH
	*
	* This is the scss file for buttons and navigation elements. 
	* These definitions are mainly used to place icons or give buttons a specific apperance.
	*
	* @author: Woldemar Henning (woldemar.Henning@gehring.de)
	*
*********************************************************************************/

// MAIN NAVIGATION
.bg-sprite {
	background: url(../images/gehring_icon_sprite.svg) no-repeat;

	&.icon--search {
		background-position-y: -50px;
	}

	&.icon--dash {
		background-position-y: -100px;
	}

	&.icon--machines {
		background-position-y: -150px;
	}

	&.icon--tools {
		background-position-y: -200px;
	}

	&.icon--eval {
		background-position-y: -250px;
	}

	&.icon--messages {
		background-position-y: -300px;
	}

	&.icon--user {
		background-position-y: -350px;
	}

	&.icon--order {
		background-position-y: -400px;
	}

	&.icon--update-manag {
		background-position-y: -1100px;
	}

	&.icon--extras {
		background-position-y: -1250px;
	}

	&.icon--powertrain {
		background-position-y: -450px;
	}

	&.icon--settings {
		background-position-y: -1200px;
	}

	&.icon--life-hone {
		background-position-y: -500px;
	}

	&.icon--deep-hone {
		background-position-y: -550px;
	}

	&.icon--smart-hone {
		background-position-y: -600px;
	}

	&.icon--micro-hone {
		background-position-y: -650px;
	}

	&.icon--laser-hone {
		background-position-y: -700px;
	}

	&.icon--single-tool {
		background-position-y: -1050px;
	}

	&.icon--plants {
		background-position-y: -1350px;
	}

	&.icon--lines {
		background-position-y: -1300px;
	}

	&.icon--plants-default {
		background-position-y: -1450px;
	}

	&.icon--lines-default {
		background-position-y: -1400px;
	}

	&.filter-icon {
		display: inline-block;
		background-position: -350px -300px;
		width: 0.75rem;
		height: 0.8125rem;
		margin-right: 0.5rem;
		transform: translateY(0.0625rem);

		&:hover {
			background-position-y: -350px;
		}
	}
}

.p-avatar {
	width: 2.6rem;
	height: 2.5rem;
	background: url(../images/gehring_icon_sprite.svg) no-repeat;
	background-position-x: -250px;

	&.disabled {
		background-position-x: -350px;
	}

	&.p-avatar--crit-error {
		background-position-y: -400px;
	}

	&.p-avatar--error {
		background-position-y: -500px;
	}

	&.p-avatar--warning {
		background-position-y: -600px;
	}

	&.p-avatar--info {
		background-position-y: -700px;
	}

	&.p-avatar--person {
		background-position-y: -800px;
	}
}

.p-avatar--neutral-person {
	img, svg {
		margin-left: 0.625rem;
		border: 1px solid $gray-400;
		border-radius: 1.25rem;
	}
}

.navbar-toggler {
	@include no-focus;
}



// BUTTONS
/*.btn--back {

	&:hover {
		border-color: $red;
		color: $red;
		background-color: transparent;

		span::before {
			background-position: -100px -500px;
		}
	}

	span {
		position: relative;
		height: 100%;
		padding-left: 1.25rem;

		&::before {
			@include icon-buttons;
			width: 0.8125rem;
			height: 0.8125rem;
			background-position: -100px -450px;
			left: 0;
			top: 0.25rem;
		}
	}
}*/

.btn-icon {
	white-space: nowrap;
	position: relative;
	padding-left: 1.875rem;

	.btn-icon--text {
		display: block;
		font-weight: 600;
		height: 1rem;
	}

	&.btn-icon-notext {
		padding-left: 1.5rem;
	}

	@include media-breakpoint-down(md) {

		&.btn-icon--export, &.btn-icon--edit-dash, &.btn-icon--add-widget, &.btn-icon--save, &.btn-icon--upload {
			padding-right: 0;

			.btn-icon--text {
				font-size: 0;
			}
		}
	}

	@include no-focus;

	&.btn-icon--export, &.btn-icon--upload {
		border: 1px solid $purple;
		background-color: $purple-lighter;

		&::before, &[disabled]:hover::before {
			@include icon-buttons;
			background-position: -250px -350px;
		}

		&:hover {
			border-color: $purple-darker;
			background-color: $purple;
		}
	}

	&.btn-icon--upload::before {
		background-position: -500px -850px;
	}

	&.btn-icon--edit-dash {
		border: 1px solid $gray-700;

		&::before, &[disabled]:hover::before {
			@include icon-buttons;
			background-position: -400px 0;
		}

		&:hover {
			background-color: transparent;
			border-color: $red;
			color: $red;

			&::before {
				background-position-x: -450px;
			}
		}
	}

	&.btn-icon--add-to-dash {
		@include only-icon-buttons;
		background-position: -250px -300px;
		width: 1.25rem;

		&:hover {
			background-position-x: -300px;
		}
	}

	&.btn-icon--add {
		@include only-icon-buttons;
		background-position: -250px -300px;
		width: 1.25rem;

		&:hover {
			background-position-x: -300px;
		}
	}

	&.btn-icon--export-widget, &.btn-icon--download:before {
		@include only-icon-buttons;
		background-position: -400px -348px;
	}

	&.btn-icon--export-widget:hover {
		background-position-x: -300px;
	}

	&.btn-icon--download {
		text-decoration: underline !important;
		cursor: pointer;

		&::before {
			content: "";
			margin: 0 0.5rem -0.0625rem 0;
		}

		&:hover {
			color: $red !important;

			&::before {
				background-position-x: -300px;
			}
		}
	}

	&.btn-icon--docu-download {
		@include only-icon-buttons;
		margin: 0.25rem 0;
		border: 1px solid $gray-600;
		padding: 0.75rem;
		border-radius: 4px;
		background-position: -394px -344px;

		&:hover {
			border-color: $red;
			background-position-x: -294px;
		}

		&[disabled]:hover {
			background-position: -394px -344px;
		}
	}

	&.btn-icon--edit {
		@include only-icon-buttons;
		width: 1.0625rem;
		background-position: -400px -50px;
		margin-left: 1rem;


		&:hover {
			background-position-x: -450px;
		}

		&[disabled]:hover {
			background-position-x: -400px;
		}
	}

	&.btn-icon--delete {
		@include only-icon-buttons;
		background-position: -400px -150px;

		&:hover {
			background-position-x: -450px;
		}

		&[disabled]:hover {
			background-position-x: -400px;
		}
	}

	&.btn-icon--new-report {
		@include only-icon-buttons;
		background-position: -450px -700px;
		width: 1.25rem;
		height: 1.25rem;
		margin-right: 0.375rem;

		&:hover {
			background-position-y: -750px;
		}

		&[disabled]:hover {
			background-position-y: -700px;
		}
	}

	&.btn-icon--exp-modal {
		@include only-icon-buttons;
		background-position: -150px -1000px;
		width: 1.375rem;
		height: 1.375rem;

		&:hover {
			background-position-x: -200px;
		}

		&[disabled]:hover {
			background-position-x: -150px;
		}
	}

	&.btn-icon--add-dash {
		height: 1.875rem;

		&::before {
			@include icon-buttons;
			width: 1.25rem;
			height: 1.25rem;
			background-position: -400px -200px;
		}

		&:hover {
			background-color: transparent;
			color: $red;

			&::before {
				background-position-x: -450px;
			}
		}

		&[disabled]:hover::before {
			background-position-x: -400px;
		}

		@include media-breakpoint-down(lg) {
			padding-right: 0 !important;

			.btn-icon--text {
				display: block;
				font-size: 0;
				height: 16px;
			}
		}

		@include media-breakpoint-down(md) {
			padding-right: 0.625rem !important;

			.btn-icon--text {
				font-size: inherit;
			}
		}
	}

	&.btn-icon--add-widget {
		border: 1px solid $gray-700;

		&::before, &[disabled]:hover::before {
			@include icon-buttons;
			width: 0.8125rem;
			background-position: -350px -100px;
		}

		&:hover {
			background-color: transparent;
			border-color: $red;
			color: $red;

			&::before {
				background-position-x: -300px;
			}
		}
	}

	&.btn-icon--delete-btn {
		border: 1px solid $gray-500;
		padding-bottom: 1.59375rem;

		&::before {
			@include icon-buttons;
			width: 0.9375rem;
			height: 0.9375rem;
			top: 0.4375rem;
			background-position: -150px -1150px;
		}
	}

	&.btn-icon--save, &.btn-icon--save-white {
		&::before {
			@include icon-buttons;
			width: 0.9375rem;
			height: 0.9375rem;
			top: 0.25rem;
			background-position-y: -800px;
		}
	}

	&.btn-icon--save {
		border: 1px solid $gray-700;

		&::before, &[disabled]:hover::before {
			background-position-x: -400px;
		}

		&:hover {
			background-color: transparent;
			border-color: $red;
			color: $red;

			&::before {
				background-position-x: -450px;
			}
		}
	}

	&.btn-icon--save-white {
		border-color: $cyan-dark;

		&::before {
			top: 0.5rem;
			background-position-x: -500px;
		}
	}

	&.btn-icon--send-message {
		border-color: $cyan-dark;

		&::before {
			@include icon-buttons;
			width: 0.8125rem;
			height: 0.8125rem;
			top: 0.5625rem;
			background-position: -450px -1000px;
		}

		&[disabled]:hover {
			color: $white;
		}
	}


	&.btn-icon--set-timerange {
		border: 1px solid $gray-700;
		padding-right: 1.5rem;

		span {
			border-right: 1px solid $gray-700;
			padding-right: 0.375rem;
		}

		&::before, &[disabled]:hover::before {
			@include icon-buttons;
			background-position: -250px 0;
			width: 0.8125rem;
		}

		&::after,, &[disabled]:hover::after {
			@include icon-buttons;
			left: inherit;
			right: 0.3125rem;
			top: 0.5625rem;
			background-position: -100px -550px;
		}

		&:hover {
			border-color: $red;
			color: $red;

			span {
				border-right-color: $red;
			}

			&::before {
				background-position-x: -300px;
			}

			&::after {
				background-position: -350px -1000px;
			}
		}
	}

	&.btn-icon--dropdown {
		border: 1px solid $purple-darker;
		background-color: $purple-light;
		color: $white;
		padding-left: 0.4375rem;
		padding-right: 2rem;
		margin-right: 0.375rem;

		&::after {
			@include icon-buttons;
			left: inherit;
			right: 0.25rem;
			top: 0.125rem;
			width: 1.25rem;
			height: 1.25rem;
			background-position: -293px -993px;
			border-left: 1px solid $white;
		}

		&:hover {
			background-color: $purple;
		}

		@include media-breakpoint-down(xs) {
			height: 1.625rem;
			padding-left: 1.5rem;
			margin-left: 0.625rem !important;

			&.select-machine::before {
				@include icon-buttons;
				background-position: -400px -1100px;
				width: 1.125rem;
				height: 1rem;
				top: 0.25rem;
			}

			&.select-piece::before {
				@include icon-buttons;
				background-position: -350px -1100px;
				width: 1.125rem;
			}
		}
	}

	&.btn-icon--submit {
		border: 1px solid $gray-600;
		padding-left: 0.375rem;
		padding-right: 1.375rem;
		margin-right: 0.375rem;

		&::after {
			@include icon-buttons;
			left: inherit;
			right: 0.3125rem;
			top: 0.375rem;
			background-position: -100px -250px;
		}

		&:hover {
			border-color: $red;
			color: $red;

			&::after {
				background-position-y: -300px;
			}
		}

		&[disabled]:hover::after {
			background-position-y: -250px;
		}

		&:not(.btn-sm) {
			padding-left: 0.5rem;
			padding-right: 1.75rem;
			margin-right: 0;

			&::after {
				top: 0.625rem;
				right: 0.5rem;
			}
		}
	}

	&.btn-icon--cart {
		border: 1px solid $gray-600;
		padding-left: 2.125rem;
		padding-right: 0.375rem;
		margin-bottom: 0.75rem;
		margin-right: 1.5rem;

		&::before, &[disabled]:hover::before {
			@include icon-buttons;
			top: 0.25rem;
			width: 1.25rem;
			height: 1rem;
			background-position: -500px -650px;
		}

		&:hover {
			border-color: $red;
			color: $red;

			&::before {
				background-position: -150px -1050px;
			}
		}
	}

	&.btn-icon--ord-details {
		border: 1px solid $gray-600;
		padding-left: 2rem;
		padding-right: 0.375rem;
		text-decoration: none;

		&::before, &[disabled]:hover::before {
			@include icon-buttons;
			top: 0.25rem;
			width: 1rem;
			height: 1rem;
			background-position: -500px -300px;
		}

		&:hover {
			border-color: $red;
			color: $red;

			&::before {
				background-position-y: -350px;
			}
		}
	}

	&.btn-icon--back {
		border: 1px solid $gray-600;
		padding-right: 0.375rem;
		padding-left: 1.5rem;
		margin-right: 0.375rem;

		&::before, &[disabled]:hover::before {
			@include icon-buttons;
			left: 0.4375rem;
			top: 0.375rem;
			background-position: -100px -450px;
		}

		&:hover {
			border-color: $red;
			color: $red !important;

			&::before {
				background-position-y: -500px;
			}
		}
	}

	&.btn-icon--arrow-l, &.btn-icon--arrow-r {
		border: 1px solid $gray-600;

		&::before {
			@include icon-buttons;
			background-position-x: -100px;
		}

		&:hover {
			border-color: $red;
			color: $red !important;
		}
	}

	&.btn-icon--arrow-l {
		&::before {
			background-position-y: -350px;
			left: 0.4375rem;
		}

		&:hover::before {
			background-position-y: -400px;
		}
	}

	&.btn-icon--arrow-r {
		&::before {
			background-position-y: -150px;
		}

		&:hover::before {
			background-position-y: -200px;
		}
	}

	&.btn-icon--next {
		margin-left: 0;
		padding-right: 1.5rem;
		padding-left: 0.5rem;

		&::after {
			@include icon-buttons;
			right: 0.5rem;
			left: unset;
			top: 0.4375rem;
			width: 0.5rem;
			height: 0.75rem;
			background-position: -250px -1000px;
		}
	}

	&.btn-icon--order {
		border: 1px solid $gray-600;
		padding-left: 2.125rem;

		&::before {
			@include icon-buttons;
			height: 1rem;
			width: 1.5rem;
			top: 0.4375rem;
			left: 0.5rem;
			background-position: -100px -1050px;
		}

		@include media-breakpoint-down(md) {
			padding-right: 0.5rem;
		}
	}

	&.btn-icon--reorder {
		border: 1px solid $gray-600;
		padding-left: 2.5rem;

		&::before {
			@include icon-buttons;
			height: 1.124rem;
			width: 1.5rem;
			top: 0.375rem;
			background-position: -500px -550px;
		}

		&:hover {
			border-color: $red;
			color: $red;

			&::before {
				background-position-y: -600px;
			}
		}

		&[disabled]:hover::before {
			background-position-y: -550px;
		}
	}

	&.btn-icon--cancel {
		border: 1px solid $gray-600;

		&::before {
			@include icon-buttons;
			width: 0.75rem;
			height: 0.75rem;
			top: 0.625rem;
			background-position: -200px -1050px;
		}

		&:hover {
			background-color: transparent;
			border-color: $red;
			color: $red;

			&::before {
				background-position-x: -250px;
			}
		}

		&[disabled]:hover::before {
			background-position-x: -200px;
		}

		@include media-breakpoint-down(md) {
			padding-right: 0.5rem;
		}
	}

	&.btn-icon--add-update {
		@include only-icon-buttons;
		width: 0.9375rem;
		height: 1rem;
		top: 0.25rem;
		background-position: -100px -1100px;
	}

	&.btn-icon--add-update:hover {
		background-position-x: -150px;
	}

	&.btn-icon--cancel-nav {
		border: 1px solid $gray-700;

		&::before {
			@include icon-buttons;
			width: 0.9375rem;
			height: 0.9375rem;
			top: 0.375rem;
			background-position: -200px -1050px;
		}

		&[disabled]:hover::before {
			background-position-x: -200px;
		}

		&:hover {
			background-color: transparent;
			border-color: $red;
			color: $red;

			&::before {
				background-position-x: -250px;
			}
		}
	}

	&.close {
		@include only-icon-buttons;
		background-position: -250px -900px;
		margin: 0 !important;
		padding: 0.5rem !important;

		&:hover {
			background-position-x: -300px;
		}
	}

	&.btn-icon--add-user, &.btn-icon--add-group, &.btn-icon--add-machine {
		border: 1px solid $cyan-dark;
		color: $cyan;

		&::before {
			@include icon-buttons;
			height: 1rem;
		}

		&:hover {
			background-color: transparent;
			border-color: $cyan-darker;
			color: $cyan-darker;
		}

		@include media-breakpoint-down(md) {
			padding-right: 0.5rem;
		}
	}

	&.btn-icon--add-user {
		padding-left: 2.1875rem;

		&::before, &[disabled]:hover::before {
			background-position: -400px -100px;
			width: 1.25rem;
			top: 0.5rem;
		}

		&hover::before {
			background-position-x: -450px;
		}
	}

	&.btn-icon--add-group {
		padding-left: 2.3125rem;

		&::before, &[disabled]:hover::before {
			background-position: -300px -1050px;
			width: 1.375rem;
			top: 0.375rem;
		}

		&hover::before {
			background-position-x: -350px;
		}
	}

	&.btn-icon--add-machine {
		padding-left: 2.1875rem;

		&::before, &[disabled]:hover::before {
			background-position: -400px -1050px;
			width: 1.25rem;
			height: 1.125rem;
			top: 0.375rem;
		}

		&hover::before {
			background-position-x: -450px;
		}
	}

	&.btn-icon--edit-green {
		border: 1px solid $cyan-dark;
		color: $cyan;
		padding-left: 2rem;

		&::before {
			@include icon-buttons;
			height: 1rem;
			width: 1.125rem;
			background-position: -500px -200px;
		}

		&:hover {
			background-color: transparent;
			border-color: $cyan-darker;
			color: $cyan-darker;

			&::before {
				background-position-y: -250px;
			}
		}

		&[disabled]:hover::before {
			background-position-y: -200px;
		}
	}

	&.btn-icon--invoice {
		&::before {
			@include icon-buttons;
			height: 1rem;
			width: 0.8125rem;
			top: 0.25rem;
			background-position: -500px -500px;
		}

		&[disabled]:hover {
			color: $white;
			border-color: $purple-lighter;
		}
	}

	&[disabled]:hover {
		border-color: inherit;
		color: inherit;
	}

	&.btn-icon--approve {
		height: 1.625rem;
		width: 1.625rem;
		padding: 0;
		background-color: $cyan;
		border-color: $cyan-darker;

		&::before {
			@include icon-buttons;
			height: 1rem;
			width: 1.0625rem;
			left: 0.25rem;
			top: 0.1875rem;
			background-position: -300px -1100px;
		}

		&:hover {
			background-color: $cyan-dark;
			border-color: $cyan-darker;
		}
	}
}

.btn-text {
	white-space: nowrap;
	position: relative;
	width: 10rem;
}

// BUTTONS IN TABLE ROWS
.tr-btn--collapse {
	@include td-buttons;
	background-position: -386px -944px;
	width: 2rem;
	height: 2rem;

	&:hover {
		background-position-x: -436px;
	}

	@include no-focus;
}

.tr-btn--status, .tr-btn--components, .tr-btn--history, .tr-btn--pieces, .tr-btn--maintenance, .tr-btn--tools, .tr-btn--sparepts, .tr-btn--config, .tr-btn--add2cart, .tr-btn--remove, .tr-btn--recalc, .tr-btn--downloads,
.tr-btn--edit, .tr-btn--delete, .tr-btn--plus, .tr-btn--minus, .tr-btn--up, .tr-btn--down {
	@include td-buttons;
}

.tr-btn--status {
	background-position-y: -300px;
}

.tr-btn--components {
	background-position-y: -350px;
}

.tr-btn--history {
	background-position-y: -400px;
}

.tr-btn--pieces {
	background-position-y: -450px;
}

.tr-btn--maintenance {
	background-position-y: -500px;
}

.tr-btn--tools {
	background-position-y: -550px;
}

.tr-btn--sparepts {
	background-position-y: -600px;
}

.tr-btn--config {
	background-position-y: -650px;
}

.tr-btn--edit {
	width: 1rem !important;
	padding-left: 1rem !important;
	margin-top: 0.25rem;

	background-position: -400px -50px;

	&:hover {
		background-position-x: -450px;
	}
}

.tr-btn--delete {
	width: 1rem !important;
	padding-left: 1rem !important;
	margin-top: 0.25rem;

	background-position: -400px -150px;

	&:hover {
		background-position-x: -450px;
	}
}

.tr-btn--plus {
	width: 0.875rem !important;
	padding-left: 1rem !important;
	margin-top: 0.375rem;
	height: 0.875rem;

	background-position: -350px -100px;

	&:hover {
		background-position-x: -300px;
	}
}

.tr-btn--minus {
	width: 0.875rem !important;
	padding-left: 1rem !important;
	margin-top: 0.375rem;
	height: 0.875rem;

	background-position: -350px -50px;

	&:hover {
		background-position-x: -300px;
	}
}

.tr-btn--up {
	width: 0.875rem !important;
	padding-left: 1rem !important;
	margin-top: 0.375rem;
	height: 0.875rem;
	background-position: -50px -1150px;

	&:hover {
		background-position-x: -0px;
	}

	&[disabled] {
		background-position: -100px -600px;
	}
}

.tr-btn--down {
	width: 0.875rem !important;
	padding-left: 1rem !important;
	margin-top: 0.375rem;
	height: 0.875rem;
	background-position: -100px -1150px;

	&:hover {
		background-position: -350px -1000px;
	}

	&[disabled] {
		background-position: -100px -550px;
	}
}

.tr-btn--add2cart {
	width: 1.6875rem;
	background-position: -450px -650px;
	cursor: pointer;

	&:hover {
		background-position: -50px -400px;
	}
}

.tr-remove-wrapper {
	display: flex !important;
	align-items: center;
	padding-left: 0.9375rem;
	height: 100%;

	.tr-btn--remove {
		background-position: -150px -950px;
		width: 1.25rem;

		&[disabled] {
			opacity: 0.6;

			&:hover {
				background-position-x: -150px;
			}
		}

		&:hover {
			background-position-x: -200px;
		}
	}
}

.tr-btn--downloads {
	background-position: -500px -700px;

	&:hover {
		background-position: -500px -750px;
	}
}

.input-group-recalc {
	width: 4.5rem;

	input, .tr-btn--recalc {
		@include no-focus;
	}

	input:focus {
		border-color: $gray-600;
	}

	.tr-btn--recalc {
		border: 1px solid $gray-600;
		background-position: -493px -395px;
		background-color: white;
		width: 1.875rem;
		height: 1.625rem;
		border-left-color: transparent;
		position: relative;

		&::before {
			content: "";
			display: block;
			height: 1.25rem;
			width: 1px;
			background-color: $gray-600;
			position: absolute;
			left: 0;
			top: 2px;
		}

		&:hover {
			border-color: $red;
			border-left-color: transparent;
			background-position-y: -445px;

			&::before {
				background-color: $red;
			}
		}
	}
}

fieldset[disabled] {
	.tr-btn--recalc, .tr-btn--recalc:hover {
		border-color: $gray-300;
		border-left-color: transparent;
		background-color: $gray-100;
		background-position-y: -395px;

		&::before {
			background-color: $gray-300;
		}
	}
}


// BUTTON ROWS IN TABLES
.td-button-row {
	.td--border-start:first-child {
		padding-left: 0;
		border-left: none;
	}

	.td--border-start:last-child {
		padding-right: 0;
	}

	.td--border-start {
		margin-left: 0 !important;
		margin-right: 0 !important;
		padding-right: 0.9375rem;
		display: flex;

		a:hover {
			cursor: pointer;

			.tr-btn--status, .tr-btn--components, .tr-btn--history-ch, .tr-btn--pieces, .tr-btn--maintenance, .tr-btn--tools, .tr-btn--sparepts, .tr-btn--config {
				background-position-x: -200px;
			}
		}
	}
}

.td-button-row.toggler {
	.dropdown-item {
		text-decoration: none;
	}
}

.clickable {

	&:hover {

		td {
			&:not(.no-tr-hover) {
				background-color: $gray-200;
				cursor: pointer;
			}
		}
	}

	td:first-child {
		&:not(.cat-toggler) {
			span {
				text-decoration: underline;
			}
		}
	}

	td.no-tr-hover:hover, td.cat-toggler:hover {
		color: $red;
		text-decoration: underline;
		cursor: pointer;

		& + .no-tr-hover {
			color: $red;
			text-decoration: underline;
		}
	}

	&.icons-in-last-col {
		td:last-child:hover {
			.btn-icon {
				background-position-x: -450px;
			}
			/*.border-left--toggler {
				display: flex;
				align-items: center;
				height: 100%;
				padding: 0 0.9375rem;
				margin-left: -0.9375rem;
				border-left: 1px solid $gray-300;
			}*/
			.border-left--icons {
				height: 100%;
				width: 100%;
				padding: 0.1875rem 0;
				top: 0;
				left: 0;
				display: flex;

				@include media-breakpoint-down(lg) {
					position: absolute;

					span:first-child {
						border-left: none;
					}
				}
			}

			.td--border-end {
				border-right: none;
			}
		}

		&:hover {
			td:last-child {
				background-color: transparent !important;
			}
		}

		&.position-relative {
			td:last-child {
				@include media-breakpoint-down(lg) {
					width: 1.875rem;
				}
			}
		}
	}
}

.icons-in-last-col {
	td:last-child {
		width: 3rem;
		position: relative;
	}
}


// TRI-STATE BUTTONS
.tri-state-buttons {
	align-items: center;
	padding: 0.9375rem 0.8125rem 0.9375rem 1.0625rem;
	position: relative;

	label {
		position: relative;
		left: 4.9375rem;
		margin-top: auto;
		margin-bottom: auto;
		max-width: calc(100% - 4.9375rem);
	}

	.tri-state__btn-reject {
		@include tri-state__btn;
		border-top-left-radius: 0.6875rem;
		border-bottom-left-radius: 0.6875rem;

		&::before {
			@include icon-buttons;
			background-position: -500px -1050px;
			width: 1.5rem;
			height: 1.375rem;
			left: 0.25rem;
			top: 0.25rem;
		}

		&.active::before, &:hover::before {
			background-position-x: -250px;
		}

		&.active {
			border-top-left-radius: 0.625rem;
			border-bottom-left-radius: 0.625rem;
			z-index: 0;

			&::before {
				top: 0.1875rem;
			}
		}

		&.inherit::before {
			background-position-x: -250px;
			top: 0.1875rem;
		}

		&.active, &.inherit {

			& ~ label {
				color: $gray-400;
			}
		}
	}

	.tri-state__btn-inherit {
		@include tri-state__btn;
		margin-left: 1.3125rem;
		z-index: 2;

		&::before {
			content: "";
			display: block;
			position: absolute;
			width: 0.625rem;
			height: 0.625rem;
			left: 0.3125rem;
			top: 0.3125rem;
			background-color: $white;
			border-radius: 0.3125rem;
		}

		&:hover::before {
			background-color: $red;
		}

		&.active {
			z-index: 0;

			&::before {
				top: 0.25rem;
				background-color: $gray-800;
			}
		}
	}

	.tri-state__btn-grant {
		@include tri-state__btn;
		border-top-right-radius: 0.6875rem;
		border-bottom-right-radius: 0.6875rem;
		margin-left: 2.625rem;
		z-index: 3;

		&::before {
			@include icon-buttons;
			background-position: -500px 0;
			left: 0.25rem;
			top: 0.25rem;
		}

		&:hover::before {
			background-position-y: -50px;
		}

		&.active::before {
			background-position-y: -100px;
		}

		&.inherit::before {
			background-position-y: -100px;
		}

		&.active {
			border-top-right-radius: 0.625rem;
			border-bottom-right-radius: 0.625rem;
		}
	}

	&.disabled > button {
		background-color: $gray-500;
		@include no-focus;
		border-color: $gray-600;
		pointer-events: none;

		&:hover {
			border-color: $gray-600;
		}

		&.tri-state__btn-reject:hover::before {
			background-position-x: -500px;
		}

		&.tri-state__btn-grant:hover::before {
			background-position: -500px 0;
		}

		& ~ label {
			color: $gray-400;
		}

		&.inherit:hover, &.active:hover {
			border-color: $gray-600;
		}

		&.tri-state__btn-inherit {
			&.active:hover::before {
				background-color: $gray-800;
			}

			&:hover::before {
				background-color: $white;
			}
		}
		// rejected button when inherited right rejected
		&.tri-state__btn-reject.inherit {			

			&:hover::before {
				background-position-x: -250px;
			}
			// check-button when inherited right rejected
			& ~ .tri-state__btn-grant:hover {
			}
		}

		&.tri-state__btn-grant.inherit:hover::before {
			background-position-y: -150px;
		}
	}
}

.icon {
	&.default-desktop, &.default-mobile {
		@include only-icon-buttons;
		margin: 0 0.3125rem 0 0;
		transform: translate(0, 0.125rem);
		background-position-x: -400px;
	}

	&.default-desktop {
		background-position-y: -850px;
		width: 0.9375rem;
	}

	&.default-mobile {
		background-position-y: -900px;
		width: 0.5625rem;
	}
}