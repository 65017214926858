﻿/********************************************************************************
	*
	* (c) 2019 - Gehring Technologies GmbH
	*
	* This is the scss file for custom style definitions 
	* needed in the footer section
	*
	* @author: Woldemar Henning (woldemar.Henning@gehring.de)
	*
*********************************************************************************/

@import "custom_variables";

.p-footer {
	background: $white;
	border-top: solid 1px $gray-200;
	position: sticky;
	left: 0;
}