﻿/********************************************************************************
	*
	* (c) 2019 - Gehring Technologies GmbH
	*
	* In this scss file additional custom mixins are defined
	*
	* @author: Woldemar Henning (woldemar.Henning@gehring.de)
	*
*********************************************************************************/

// CUSTOM MIXINS
@mixin nav-appearance {
	color: $white;
	font-size: $font-size-lg;
	font-weight: $headings-font-weight;
	padding: 0;
	display: flex;
	align-items: center;

	&.active .p-mainnav__navicon {
		width: 1.25rem;
	}
}

@mixin nav-icon-size {
	height: 1.375rem;
	display: inline-block;
	width: 1.6875rem;
}

@mixin navbar-toggler-icons {
	@include nav-icon-size;
	margin: 0.9375rem 0 1.3125rem 1.375rem;

	&:hover {
		background-position-x: -50px;
	}
}

@mixin arrow-icon {
	width: 0.5rem;
	height: 0.75rem;
	display: inline-block;
	background-position: -250px -1000px;
}

@mixin icon-sprite {
	background-image: url(../images/gehring_icon_sprite.svg);
}

@mixin icon-buttons {
	content: "";
	position: absolute;
	left: 0.5rem;
	top: 0.3125rem;
	display: block;
	width: 0.75rem;
	height: 0.8125rem;
	@include icon-sprite;
}

@mixin only-icon-buttons {
	border: none;
	display: inline-block;
	width: 0.75rem;
	height: 0.9375rem;
	padding: 0;
	@include icon-sprite;
	background-color: transparent;
	margin-left: 0.625rem;

	&:focus {
		outline: none;
	}
}

@mixin td--border {
	display: flex !important;
	width: 100%;
	height: 100%;
	align-items: center;
}

@mixin td-buttons {
	border: none;
	background-color: transparent;
	display: block;
	width: 1.4375rem;
	height: 1.25rem;
	@include icon-sprite;
	background-position-x: -150px;
	padding: 0;

	&:hover {
		background-position-x: -200px;
	}
}

@mixin dropdown-pointer {
	background: $white;
	box-shadow: 0 2px 20px -5px $gray-400;

	&::before {
		content: "";
		border-top: none;
		border-right: 0.5rem solid transparent;
		border-bottom: 0.5rem solid $white;
		border-left: 0.5rem solid transparent;
		position: absolute;
		top: -0.375rem;
	}
}

@mixin tabs-go-vertical {
	position: relative;
	border-bottom: 1px solid $gray-500;
	padding-bottom: 0.25rem;
	padding-left: 1.25rem;
	margin-bottom: 0.25rem;

	.tab-nav__btn--toggl {
		border-color: $gray-500;
		white-space: nowrap;
		position: relative;
		padding-right: 1.75rem;
		margin-left: -1.125rem;

		span {
			border-right: 1px solid $gray-500;
			padding-right: 0.625rem;
		}

		&::after {
			@include icon-buttons;
			left: inherit;
			right: 0.4375rem;
			top: 0.5625rem;
			background-position: -100px -550px;
		}

		&:focus {
			outline: none;
			box-shadow: none;
		}
	}

	.tab-nav__nav-block, &.tab-nav__nav-block {
		position: absolute;
		z-index: 3000;
		margin-top: 0.125rem;
		left: 1.25rem;
		top: 2.0625rem;
		padding: 0.25rem;
		border: 1px solid $gray-800;
		background: $white;
		box-shadow: 0 2px 20px -5px $gray-400;

		.nav {
			display: inline-block;

			&.nav-tabs {
				margin: 0;
				border: none;
				padding: 0;
			}

			.nav-item {
				margin: 0;

				.nav-link, nav-link.active {
					border-color: transparent transparent $gray-500 transparent;
					padding: 0.5rem;
					margin: 0 !important;
					transform: translate(0);
				}

				&:last-child {
					.nav-link, nav-link.active {
						border-color: transparent;
					}
				}
			}
		}
	}
}

@mixin no-focus {
	&:focus {
		outline: none;
		box-shadow: none;
		border-color: transparent;
	}
}

@mixin bg-tooltype {
	background-size: cover;
	background-repeat: no-repeat;
	background-color: $white;
	background-position: center center;

	@include media-breakpoint-down(xs) {
		min-height: 19.375rem;
	}

	@include media-breakpoint-up(sm) {
		min-height: 22.75rem;
	}

	@include media-breakpoint-up(sm) {
		min-height: 26.1875rem;
	}
}

@mixin tri-state__btn {
	width: 1.375rem;
	height: 1.375rem;
	border: 1px solid $gray-800;
	background-color: $gray-700;
	position: absolute;
	@include no-focus;
	z-index: 1;

	&:hover {
		border-color: $red;
	}

	&:hover, &.active, &.inherit {
		z-index: 10;
	}

	&.active, &.inherit {
		background-color: $gray-300;
		border-color: $gray-500;
		height: 1.25rem;
	}
}

@mixin chart-tooltip {
	.arrow_box {

		.arrow_box__head {
			font-weight: 600;
			padding: 0.5rem;
			background-color: rgba(230, 230, 230, 0.65);
			border-bottom: 1px solid $gray-300;
		}

		p {
			padding: 0.5rem;
			margin: 0;
		}
	}
}

@mixin custom-no-gutters-x {
	> .row, &.row {
		> .col-12 {
			padding-left: 0;
			padding-right: 0;
		}

		> .col, > [class*="col-"] {
			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}
}
