﻿/********************************************************************************
	*
	* (c) 2019 - Gehring Technologies GmbH
	*
	* This is the scss file for custom style definitions 
	* needed in the header section
	*
	* @author: Woldemar Henning (woldemar.Henning@gehring.de)
	*
*********************************************************************************/

@import "../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";
@import "custom_variables";
@import "custom_mixins";

// HEADER
.p-header--fullScreen {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 9;
}

.p-header {
	background: $white;
	border-bottom: solid 1px $gray-200;
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	z-index: 10;

	.p-header__fullScreen {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;
	}

	.p-header__mainnavtoggler {
		min-width: 4.125rem;
		margin-right: 3.75rem;

		@include media-breakpoint-down(md) {
			margin-right: 1.875rem;
		}

		@media (max-width: 374.98px) {
			margin-right: 0.25rem;
		}

		.navbar-toggler__placeholder {
			width: 1.6875rem;
			height: 1.375rem;
			margin: 1.5625rem 0.625rem 0 1.375rem;
			display: inline-block;
		}

		.navbar-toggler__dynamicPlaceholder {
			-webkit-transition: width 0.35s ease;
			-moz-transition: width 0.35s ease;
			-o-transition: width 0.35s ease;
			transition: width 0.35s ease;
			overflow: hidden;
		}
		// Toggle modes
		&.togglemode-auto {
			.navbar-toggler__dynamicPlaceholder {
				flex: 0 1 auto;
				height: 1.375rem;
			}

			&.toggled {
				.navbar-toggler__dynamicPlaceholder {
					@include media-breakpoint-down(md) {
						width: 0;
					}

					@include media-breakpoint-up(md) {
						width: 0;
					}
				}
			}

			&:not(.toggled) {
				.navbar-toggler__dynamicPlaceholder {
					@include media-breakpoint-down(md) {
						width: 0;
					}

					@include media-breakpoint-up(md) {
						width: 10.9375rem;
					}
				}
			}
		}

		&.togglemode-visible {
			.navbar-toggler__dynamicPlaceholder {
				flex: 0 1 auto;
				height: 1.375rem;
			}

			&.toggled {
				.navbar-toggler__dynamicPlaceholder {
					width: 0;
				}
			}

			&:not(.toggled) {
				.navbar-toggler__dynamicPlaceholder {
					width: 10.9375rem;
				}
			}
		}

		&.togglemode-hidden {
			.navbar-toggler__dynamicPlaceholder {
				display: none;
			}
		}
	}

	.p-header__logo {
		width: auto;
		height: 3rem;
		margin: 0.6875rem 1.25rem 0.625rem 1.25rem;

		@include media-breakpoint-down(md) {
			height: 2rem;
		}
	}

	.p-header__navbar {
		padding-right: 0;
		margin-right: 60px;

		.p-header__navtoggler {
			background: url(../images/gehring_icon_sprite.svg) no-repeat;
			background-position-x: -100px;
			border: none;
			width: 1.6875rem;
			height: 1.8125rem;

			&.p-header__navtoggler--neutr {
				background-position-y: -650px;
			}

			&.p-header__navtoggler--l {
				background-position-y: -700px;
			}

			&.p-header__navtoggler--m {
				background-position-y: -750px;
			}

			&.p-header__navtoggler--r {
				background-position-y: -800px;
			}

			&.p-header__navtoggler--lm {
				background-position-y: -850px;
			}

			&.p-header__navtoggler--mr {
				background-position-y: -900px;
			}

			&.p-header__navtoggler--lr {
				background-position-y: -950px;
			}

			&.p-header__navtoggler--lmr {
				background-position-y: -1000px;
			}
		}

		ul {
			margin-left: 1.5625rem;

			button.nav-link {
				background: transparent;
				border: none;
				@include no-focus;
			}

			.dropdown-toggle {
				cursor: pointer;

				&::after {
					border: none;
				}
			}

			.p-header__dropdown-content {
				background: url(../images/gehring_icon_sprite.svg) no-repeat;
				background-position-x: 0;
				display: inline-block;
				position: relative;

				&::after {
					border: none;
				}

				&.p-header__navbar__note--empty, &.p-header__navbar__note--full {
					width: 25px;
					height: 26px;
					background-position-y: -750px;
				}

				&.p-header__navbar__message--empty, &.p-header__navbar__message--full {
					width: 33px;
					height: 27px;
					background-position-y: -848px;
				}

				&.p-header__navbar__cart--empty, &.p-header__navbar__cart--full {
					width: 27px;
					height: 22px;
					background-position-y: -950px;
				}

				&.p-header__navbar__note--full {
					background-position-y: -800px;

					span {
						position: absolute;
						right: 0;
						top: 0;
					}
				}

				&.p-header__navbar__message--full {
					background-position-y: -900px;
				}

				&.p-header__navbar__cart--full {
					background-position-y: -1000px;
				}

				&.p-header__navbar__cart--empty, &.p-header__navbar__note--full, &.p-header__navbar__message--full, &.p-header__navbar__cart--full {
					span {
						position: absolute;
						display: block;
						width: 0.8125rem;
						height: 0.8125rem;
						text-align: center;
						right: 0;
						top: 0;
						color: $white;
						font-size: 0.5625rem;
					}
				}

				&:hover {
					&.p-header__navbar__note--empty, &.p-header__navbar__message--empty, &.p-header__navbar__cart--empty, &.p-header__navbar__note--full, &.p-header__navbar__message--full, &.p-header__navbar__cart--full {
						background-position-x: -50px;
					}
				}
			}

			a {
				position: relative;

				&.dropdown-item.active, &.dropdown-item:active {
					background-color: $gray-100;
				}
			}
		}

		.dropdown-menu {
			border-radius: 0;
			border: none;
			padding: 0 0.25rem;

			@include dropdown-pointer;

			a {
				padding-left: 0.5rem;
				padding-right: 0.5rem;
			}
		}

		.p-header__note--full__content, .p-header__message--full__content, .p-header__cart--full__content {
			min-width: 20rem;

			h6 {
				margin-bottom: 0.25rem;
			}

			.p-header__dropdown__close {
				display: block;
				width: 1rem;
				height: 1rem;
				margin: 1.25rem 0.5rem;
				background: url(../images/gehring_icon_sprite.svg) no-repeat -250px -900px;

				&:hover {
					background-position-x: -300px;
				}
			}

			.p-header__dropdown__title {
				margin: 1.25rem 0.5rem;
			}

			.p-header__dropdown__item-header {
				color: $gray-800;
				font-weight: 600;
			}

			.p-header__dropdown__item-text {
				font-size: $small-font-size;
			}

			.p-header__dropdown-link {

				&:hover {
					text-decoration: none;

					.p-header__dropdown__item-header, .p-header__dropdown__item-text {
						color: $red;
						text-decoration: underline;
					}
				}
			}

			.p-avatar--neutral-person {
				width: 2.6rem;
				height: 2.5rem;
				margin-right: 0.625rem;

				img, svg {
					margin-left: 0;
				}
			}
		}

		.p-header__note--full__content {

			&::before {
				left: 0.5rem;
			}
		}

		.p-header__message--full__content {
			left: -4.125rem;

			&::before {
				left: 5rem;
			}
		}

		.p-header__cart--full__content {
			left: -8.75rem;
			max-height: calc(100vh - 8.125rem);

			&::before {
				left: 9.75rem;
			}
		}

		ul:last-child {
			li > a {
				padding: 0 0 0 1.875rem;
				border-left: 1px solid $gray-400;

				.p-header__dropdown-content {
					background: none;

					&::after {
						content: '';
						display: inline-block;
						background: url(../images/gehring_icon_sprite.svg) no-repeat -100px -550px;
						width: 11px;
						height: 7px;
						vertical-align: middle;
						margin-left: 0.625rem;
					}
				}

				&:hover {
					color: $red;
				}
			}

			li.show {
				& a .p-header__dropdown-content::after {
					content: '';
					display: inline-block;
					background-position: -100px -600px;
				}
			}

			.p-avatar--neutral-person {
				margin-left: 0.625rem;

				img {
					width: 40px;
					height: 40px;
				}
			}

			.dropdown-menu {
				right: 10%;
				left: 10%;
				z-index: 2;

				a {
					min-height: 1.875rem;
					border-bottom: 1px solid $gray-400;

					&:hover {
						color: $red;
					}

					&:last-child {
						border-bottom: none;
					}
				}
			}
		}

		@include media-breakpoint-down(lg) {
			.navbar-collapse {
				position: absolute;
				right: -1.75rem;
				top: 3.75rem;
				padding: 0.25rem;
				@include dropdown-pointer;

				&::before {
					right: 2rem;
				}

				.d-flex.flex-wrap {
					justify-content: space-between;
				}

				ul {
					margin-left: 0.675rem;
					margin-right: 0.675rem;

					.dropdown-menu {
						position: absolute;
						right: -1.25rem;
						left: unset;
						top: 2.75rem;

						&::before {
							right: 1.625rem;
						}

						&.p-header__note--full__content {
							right: -7.5rem;

							&::before {
								right: 8rem;
								left: unset;
							}
						}

						&.p-header__message--full__content {
							right: -3.75rem;

							&::before {
								right: 4.5625rem;
								left: unset;
							}
						}

						&.p-header__cart--full__content {
							right: -0.5625rem;

							&::before {
								right: 1rem;
								left: unset;
							}
						}
					}

					&:last-child {
						margin-left: 0;
						margin-right: 0;
						border-top: 1px solid $gray-400;

						.nav-link {
							display: none !important;
						}

						.dropdown-menu {
							position: relative;
							display: block;
							right: 0;
							top: 0;
							left: 0;
							box-shadow: none;
							padding: 0;
							z-index: 2;

							&::before {
								border: none;
								position: unset;
							}
						}
					}
				}
			}
		}
	}
}
