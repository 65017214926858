﻿/********************************************************************************
*
* (c) 2021 - Gehring Technologies GmbH
*
* This is the definition for the rc-input-element
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

// import custom variable override
@import "custom_variables";

.timeSelection {
    .timeSelection_container {
        .timeSelection_datePicker {
        }

        .timeSelection_currentTime {
        }
    }

    .timeSelection_fixedTime {
    }

    &.timeSelection_currentTimeEnabled {
        .timeSelection_container {
            display: flex;

            .timeSelection_datePicker {
            }

            .timeSelection_currentTime {
                margin-left: 1rem;
            }
        }
    }
}