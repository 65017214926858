﻿/********************************************************************************
	*
	* (c) 2019 - Gehring Technologies GmbH
	*
	* This is the scss file for custom variable definitions. 
	* These definitions are used to override the variable definitions in bootstrap 4.3.
	*
	* @author: Woldemar Henning (woldemar.Henning@gehring.de)
	*
*********************************************************************************/

// Color

$gray-100: #f8f8f8;
$gray-150: #f0f0f0;
$gray-200: #e6e6e6;
$gray-300: #dedcdf;
$gray-400: #acabab;
$gray-500: #7b7b7b;
$gray-600: #4d4d4d;
$gray-700: #3d3d3d;
$gray-800: #1a1a1a;

$white: #ffffff;
$purple: #411a91;
$pink: #b40179;
$red: #e30613;
$orange: #eb5f00;
$yellow: #d4ba13;
$green: #29c600;
$cyan: #00957c;

$purple-xx-light: #f7f5f8;
$purple-x-light: #f0eef4;
$purple-lighter: #5e419a;
$purple-light: #512e98;
$purple-dark: #361084;
$purple-darker: #2e0a78;

$pink-lighter: #cf4aa3;
$pink-light: #c52591;
$pink-dark: #900061;
$pink-darker: #70004c;

$mainnav-bg-light: #4f3f41;
$mainnav-bg-dark: #902128;

$red-lighter: #f6576b;
$red-light: #f52e45;
$red-dark: #b30015;
$red-darker: #8d0010;

$orange-lighter: #ff9e5a;
$orange-light: #ff8331;
$orange-dark: #bc4c00;
$orange-darker: #913b00;

$yellow-lighter: #e1cd50;
$yellow-light: #dec732;
$yellow-dark: #c1a704;
$yellow-darker: #b09900;

$green-lighter: #6dde50;
$green-light: #4dd728;
$green-dark: #219e00;
$green-darker: #1a7b00;

$cyan-lighter: #40b39f;
$cyan-light: #1ea38d;
$cyan-dark: #007763;
$cyan-darker: #005d4d;

$primary: $purple;
$secondary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $orange;
$danger: $red-dark;
$light: $gray-200;
$dark: $gray-800;

// body
$body-bg: $purple-x-light;
$body-color: $gray-600;

// font
$font-family-sans-serif: 'Open Sans',Arial,sans-serif;
$font-size-base: 0.8125rem;
$font-size-lg: $font-size-base * 1.23077;
$font-size-sm: $font-size-base * .84615;
$small-font-size: $font-size-sm;

$h1-font-size: $font-size-base * 2.46154;
$h2-font-size: $font-size-base * 2.15385;
$h3-font-size: $font-size-base * 1.84615;
$h4-font-size: $font-size-base * 1.53846;
$h5-font-size: $font-size-base * 1.23077;
$h6-font-size: $font-size-base;

$headings-font-weight: 600;

// buttons
$input-btn-padding-x: .5rem;

//$component-active-color: $red;
$component-active-bg: $red;

// form elements
$input-border-color: $gray-500;
$input-bg: $white;
$input-disabled-bg: $gray-100;
$input-disabled-border-color: $gray-300;
$input-disabled-color: $gray-400;
$input-placeholder-color: $gray-400;

// Layout
$zindex-modal-backdrop: 3050;
$zindex-modal: 3050;
$zindex-tooltip: 3052;

// Link
$link-color: $gray-600;
$link-hover-color: $red;